import React, { FC } from 'react';
import './ComingSoonImg.css';
import logo from '../../../assets/images/logo/miel-logo.png';
// import logo from '../../../assets/images/logo/logo-1.svg';

const ComingSoon: FC = () => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon-logo h-100">
                <img src={logo} alt="" className='coming-soon-img' />
            </div>
            <div className="coming-soon-overlay"></div>
            <div className="text-center text-light">
                <h1 className='fw-600 ff-3 ' >COMING SOON</h1>
            </div>
        </div>
    );
}

export default ComingSoon;

// import React, { FC } from 'react';
// import './ComingSoon.css';
// import logo from '../../../assets/images/logo/logo-1.svg';
// import video from '../../../assets/videos/soon/soon-3.mp4';

// const ComingSoon: FC = () => {

//     return (
//         <div className="coming-soon-container">
//             <div className="coming-soon-logo d-fle justify-content-start calign-items-center h-100">
//                 <img src={logo} alt="" className='coming-soon-img' />
//             </div>
//             <div className="coming-soon-overlay"></div>
//             <video autoPlay loop muted className="coming-soon-video">
//                 <source src={video} type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//             <div className="text-center text-light">
//                 {/* Replace background-image with a video element */}

//                 <h1 className='fw-600 ff-0' >COMING SOON</h1>
//             </div>
//         </div>
//     );
// }

// export default ComingSoon;

